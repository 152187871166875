var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.classData)?_c('div',{staticClass:"text-center mt-15 ems-blue-grey--text"},[_vm._v(" ( 無資料 ) ")]):[(_vm.classData)?_c('div',{directives:[{name:"permission",rawName:"v-permission",value:(['SCH_ADMIN', 'CLASS_USER']),expression:"['SCH_ADMIN', 'CLASS_USER']"}],staticClass:"mt-2 pr-3 text-right"},[_c('v-btn',{attrs:{"elevation":"0","color":"accent","disabled":!_vm.valid},on:{"click":_vm.checkSubmit}},[_vm._v(" 儲存修改 ")])],1):_vm._e(),(!_vm.valid)?_c('div',{staticClass:"text-right pr-3 error--text text-subtitle-2"},[_vm._v(" *尚有資料未填寫或格式錯誤 ")]):_vm._e(),(_vm.classData)?_c('div',{staticClass:"pa-3"},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card',{staticClass:"mx-auto pa-3 ems-opacity-50-bg frosted-glass",attrs:{"elevation":"0"}},[_c('v-card-title',{staticClass:"font-weight-bold accent--text"},[_vm._v(" 基本資料 ")]),_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","lg":"6"}},[_c('div',{staticClass:"text-subtitle-1 grey--text"},[_vm._v("教室編號")]),_c('v-text-field',{attrs:{"outlined":"","dense":"","disabled":"","readonly":""},model:{value:(_vm.classData.serialNumber),callback:function ($$v) {_vm.$set(_vm.classData, "serialNumber", $$v)},expression:"classData.serialNumber"}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","lg":"6"}},[_c('div',{staticClass:"text-subtitle-1 grey--text"},[_vm._v("班級名稱")]),_c('v-text-field',{attrs:{"outlined":"","dense":"","disabled":!['SCH_ADMIN'].includes(_vm.userRole),"rules":[
                    function (v) { return !!v || '必填'; },
                    function (v) {
                      var pattern = /^[_0-9a-zA-Z.#\s\u4e00-\u9fa5]+$/
                      return pattern.test(v) || '格式不符'
                    }
                  ]},model:{value:(_vm.classData.name),callback:function ($$v) {_vm.$set(_vm.classData, "name", $$v)},expression:"classData.name"}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","lg":"6"}},[_c('div',{staticClass:"text-subtitle-1 grey--text"},[_vm._v("建築大樓")]),_c('v-text-field',{attrs:{"outlined":"","dense":"","disabled":"","readonly":""},model:{value:(_vm.classData.building),callback:function ($$v) {_vm.$set(_vm.classData, "building", $$v)},expression:"classData.building"}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","lg":"6"}},[_c('div',{staticClass:"text-subtitle-1 grey--text"},[_vm._v("樓層")]),_c('v-text-field',{attrs:{"outlined":"","dense":"","disabled":"","readonly":""},model:{value:(_vm.classData.floor),callback:function ($$v) {_vm.$set(_vm.classData, "floor", $$v)},expression:"classData.floor"}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"text-subtitle-1 grey--text"},[_vm._v("使用者E-mail")]),_c('v-textarea',{attrs:{"readonly":"","outlined":"","dense":"","no-resize":"","rows":"3"},model:{value:(_vm.classData.userEmail),callback:function ($$v) {_vm.$set(_vm.classData, "userEmail", $$v)},expression:"classData.userEmail"}})],1)],1)],1)],1),_c('v-card',{staticClass:"mx-auto pa-3 mt-6 ems-opacity-50-bg frosted-glass",attrs:{"elevation":"0"}},[_c('v-card-title',{staticClass:"font-weight-bold accent--text"},[_vm._v(" 儲值資料 ")]),_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","lg":"6"}},[_c('div',{staticClass:"text-subtitle-1 grey--text"},[_vm._v("餘額不足警示值")]),_c('v-text-field',{attrs:{"outlined":"","dense":"","disabled":!['CLASS_USER'].includes(_vm.userRole),"rules":[
                    function (v) {
                      if (!v) { return true }
                      return Number(v).toString() === String(v) || '格式不符'
                    }
                  ]},model:{value:(_vm.classData.insufficientBalanceWarning),callback:function ($$v) {_vm.$set(_vm.classData, "insufficientBalanceWarning", $$v)},expression:"classData.insufficientBalanceWarning"}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","lg":"6"}},[_c('div',{staticClass:"text-subtitle-1 grey--text"},[_vm._v(" 學校冷氣統一收費費率 ")]),_c('v-text-field',{attrs:{"outlined":"","dense":"","type":"number","disabled":""},model:{value:(_vm.classData.schoolCardRate),callback:function ($$v) {_vm.$set(_vm.classData, "schoolCardRate", $$v)},expression:"classData.schoolCardRate"}})],1),_c('v-col',{directives:[{name:"permission-without-city",rawName:"v-permission-without-city",value:(['keelung', 'changhua']),expression:"['keelung', 'changhua']"}],staticClass:"pt-0",attrs:{"cols":"12","lg":"6"}},[_c('div',{staticClass:"text-subtitle-1 grey--text"},[_vm._v("儲值卡卡號")]),_vm._l((_vm.classData.cardList),function(val,key){return _c('v-text-field',{key:key,attrs:{"outlined":"","dense":"","disabled":!['SCH_ADMIN', 'CLASS_USER'].includes(_vm.userRole),"rules":[
                    function (v) { return !!v || '必填'; },
                    function (v) {
                      var cardList = JSON.parse(
                        JSON.stringify(_vm.classData.cardList)
                      ).filter(function (item, index) {
                        return index !== key
                      })
                      return !cardList.includes(v) || '相同卡號已存在'
                    },
                    function (v) {
                      var pattern = /^[_0-9a-zA-Z]+$/
                      return pattern.test(v) || '格式不符'
                    }
                  ]},model:{value:(_vm.classData.cardList[key]),callback:function ($$v) {_vm.$set(_vm.classData.cardList, key, $$v)},expression:"classData.cardList[key]"}},[_c('v-icon',{directives:[{name:"permission",rawName:"v-permission",value:(['SCH_ADMIN', 'CLASS_USER']),expression:"['SCH_ADMIN', 'CLASS_USER']"}],attrs:{"slot":"append"},on:{"click":function($event){_vm.classData.cardList.splice(key, 1)
                      _vm.$refs.form.validate()}},slot:"append"},[_vm._v(" mdi-close ")])],1)}),_c('v-btn',{directives:[{name:"permission",rawName:"v-permission",value:(['SCH_ADMIN', 'CLASS_USER']),expression:"['SCH_ADMIN', 'CLASS_USER']"}],attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.classData.cardList.push(null)}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],2)],1)],1)],1),_c('v-card',{staticClass:"mx-auto pa-3 mt-6 ems-opacity-50-bg frosted-glass",attrs:{"elevation":"0"}},[_c('v-card-title',{staticClass:"font-weight-bold accent--text"},[_vm._v(" 班級設定 ")]),_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","lg":"6"}},[_c('div',{staticClass:"text-subtitle-1 grey--text"},[_vm._v("班級人數")]),_c('v-text-field',{attrs:{"outlined":"","dense":"","disabled":!['SCH_ADMIN', 'CLASS_USER'].includes(_vm.userRole),"rules":[
                    function (v) {
                      if (!v) { return true }
                      return Number(v).toString() === String(v) || '格式不符'
                    }
                  ]},model:{value:(_vm.classData.students),callback:function ($$v) {_vm.$set(_vm.classData, "students", $$v)},expression:"classData.students"}})],1)],1)],1)],1)],1)],1):_vm._e()],_c('v-dialog',{attrs:{"persistent":"","width":"350"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(!_vm.update.msg && !_vm.update.loading)?_c('v-card',[_c('v-card-text',{staticClass:"pt-5 headline text-center"},[_vm._v(" 確定要修改嗎？ ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"elevation":"0"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" 取消 ")]),_c('v-btn',{attrs:{"color":"primary","elevation":"0"},on:{"click":_vm.submit}},[_vm._v(" 更新 ")]),_c('v-spacer')],1)],1):(_vm.update.loading)?_c('v-card',[_c('div',{staticClass:"text-center pa-5"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1)]):_c('v-card',[_c('v-card-text',{staticClass:"pt-5 headline text-center"},[_vm._v(" "+_vm._s(_vm.update.msg)+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"elevation":"0"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" 關閉 ")]),_c('v-spacer')],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }