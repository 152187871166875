<template>
  <div>
    <div v-if="!classData" class="text-center mt-15 ems-blue-grey--text">
      ( 無資料 )
    </div>
    <template v-else>
      <div
        v-if="classData"
        class="mt-2 pr-3 text-right"
        v-permission="['SCH_ADMIN', 'CLASS_USER']"
      >
        <v-btn
          elevation="0"
          @click="checkSubmit"
          color="accent"
          :disabled="!valid"
        >
          儲存修改
        </v-btn>
      </div>
      <div class="text-right pr-3 error--text text-subtitle-2" v-if="!valid">
        *尚有資料未填寫或格式錯誤
      </div>
      <div class="pa-3" v-if="classData">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card
            class="mx-auto pa-3 ems-opacity-50-bg frosted-glass"
            elevation="0"
          >
            <v-card-title class="font-weight-bold accent--text">
              基本資料
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" lg="6" class="pt-0">
                  <div class="text-subtitle-1 grey--text">教室編號</div>
                  <v-text-field
                    v-model="classData.serialNumber"
                    outlined
                    dense
                    disabled
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" lg="6" class="pt-0">
                  <div class="text-subtitle-1 grey--text">班級名稱</div>
                  <v-text-field
                    v-model="classData.name"
                    outlined
                    dense
                    :disabled="!['SCH_ADMIN'].includes(userRole)"
                    :rules="[
                      (v) => !!v || '必填',
                      (v) => {
                        const pattern = /^[_0-9a-zA-Z.#\s\u4e00-\u9fa5]+$/
                        return pattern.test(v) || '格式不符'
                      }
                    ]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" lg="6" class="pt-0">
                  <div class="text-subtitle-1 grey--text">建築大樓</div>
                  <v-text-field
                    v-model="classData.building"
                    outlined
                    dense
                    disabled
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" lg="6" class="pt-0">
                  <div class="text-subtitle-1 grey--text">樓層</div>
                  <v-text-field
                    v-model="classData.floor"
                    outlined
                    dense
                    disabled
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="pt-0">
                  <div class="text-subtitle-1 grey--text">使用者E-mail</div>
                  <v-textarea
                    readonly
                    v-model="classData.userEmail"
                    outlined
                    dense
                    no-resize
                    rows="3"
                  >
                  </v-textarea>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card
            class="mx-auto pa-3 mt-6 ems-opacity-50-bg frosted-glass"
            elevation="0"
          >
            <v-card-title class="font-weight-bold accent--text">
              儲值資料
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" lg="6" class="pt-0">
                  <div class="text-subtitle-1 grey--text">餘額不足警示值</div>
                  <v-text-field
                    v-model="classData.insufficientBalanceWarning"
                    outlined
                    dense
                    :disabled="!['CLASS_USER'].includes(userRole)"
                    :rules="[
                      (v) => {
                        if (!v) return true
                        return Number(v).toString() === String(v) || '格式不符'
                      }
                    ]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" lg="6" class="pt-0">
                  <div class="text-subtitle-1 grey--text">
                    學校冷氣統一收費費率
                  </div>
                  <v-text-field
                    v-model="classData.schoolCardRate"
                    outlined
                    dense
                    type="number"
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  lg="6"
                  class="pt-0"
                  v-permission-without-city="['keelung', 'changhua']"
                >
                  <div class="text-subtitle-1 grey--text">儲值卡卡號</div>
                  <v-text-field
                    v-for="(val, key) in classData.cardList"
                    :key="key"
                    v-model="classData.cardList[key]"
                    outlined
                    dense
                    :disabled="!['SCH_ADMIN', 'CLASS_USER'].includes(userRole)"
                    :rules="[
                      (v) => !!v || '必填',
                      (v) => {
                        const cardList = JSON.parse(
                          JSON.stringify(classData.cardList)
                        ).filter((item, index) => {
                          return index !== key
                        })
                        return !cardList.includes(v) || '相同卡號已存在'
                      },
                      (v) => {
                        const pattern = /^[_0-9a-zA-Z]+$/
                        return pattern.test(v) || '格式不符'
                      }
                    ]"
                  >
                    <v-icon
                      slot="append"
                      @click="
                        classData.cardList.splice(key, 1)
                        $refs.form.validate()
                      "
                      v-permission="['SCH_ADMIN', 'CLASS_USER']"
                    >
                      mdi-close
                    </v-icon>
                  </v-text-field>
                  <v-btn
                    icon
                    color="primary"
                    @click="classData.cardList.push(null)"
                    v-permission="['SCH_ADMIN', 'CLASS_USER']"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card
            class="mx-auto pa-3 mt-6 ems-opacity-50-bg frosted-glass"
            elevation="0"
          >
            <v-card-title class="font-weight-bold accent--text">
              班級設定
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" lg="6" class="pt-0">
                  <div class="text-subtitle-1 grey--text">班級人數</div>
                  <v-text-field
                    v-model="classData.students"
                    outlined
                    dense
                    :disabled="!['SCH_ADMIN', 'CLASS_USER'].includes(userRole)"
                    :rules="[
                      (v) => {
                        if (!v) return true
                        return Number(v).toString() === String(v) || '格式不符'
                      }
                    ]"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-form>
      </div>
    </template>
    <v-dialog v-model="dialog" persistent width="350">
      <v-card v-if="!update.msg && !update.loading">
        <v-card-text class="pt-5 headline text-center">
          確定要修改嗎？
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn elevation="0" @click="dialog = false"> 取消 </v-btn>
          <v-btn color="primary" elevation="0" @click="submit"> 更新 </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
      <v-card v-else-if="update.loading">
        <div class="text-center pa-5">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
      </v-card>
      <v-card v-else>
        <v-card-text class="pt-5 headline text-center">
          {{ update.msg }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn elevation="0" @click="dialog = false"> 關閉 </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import BranchClass from '@/api/ems/BranchClass'

export default {
  props: {
    tagId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      valid: false,
      classData: null,
      dialog: false,
      update: {
        loading: false,
        msg: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      userData: 'user/userData'
    }),
    userRole() {
      return this.userData.role.role
    }
  },
  mounted() {
    this.getInfo()
  },
  methods: {
    ...mapActions({
      getTags: 'page/getTags',
      getPage: 'page/getPage',
      setOverlayLoading: 'page/setOverlayLoading'
    }),
    async checkSubmit() {
      const validate = await this.$refs.form.validate()
      if (!validate) {
        const el = document.querySelector('.v-messages.error--text')
        if (el) this.$vuetify.goTo(el, { offset: 80 })
        return
      }
      this.update.loading = false
      this.update.msg = ''
      this.dialog = true
    },
    async submit() {
      const validate = this.$refs.form.validate()
      if (!validate) return
      this.update.loading = true
      try {
        await BranchClass.update(this.tagId, this.classData)
        this.update.msg = '更新成功'
        this.getTags()
        setTimeout(() => {
          this.getPage(this.tagId)
        }, 500)
      } catch (error) {
        this.update.msg = '更新失敗'
        console.error(error)
      } finally {
        this.update.loading = false
      }
    },
    async getInfo() {
      if (!this.tagId) return
      try {
        this.setOverlayLoading(true)
        const { data } = await BranchClass.index(this.tagId)
        this.classData = data
      } finally {
        this.setOverlayLoading(false)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .v-text-field:not(.error--text) {
    &.v-input--is-disabled,
    &.v-input--is-readonly {
      .v-input__control > .v-input__slot {
        background-color: #d6e1f1;
        fieldset {
          border: none;
        }
        input,
        textarea,
        .v-select__selection {
          color: #889bb7;
        }
      }
    }
    .v-input__control > .v-input__slot {
      background-color: #ffffff;
      fieldset {
        border-color: #acc0dd;
      }
      input,
      textarea,
      .v-select__selection {
        color: var(--v-accent-base);
      }
    }
  }
}
</style>
