import settings from '@/settings'

/**
 * 班級基本資訊
 */
class BranchClass {
  /**
   * 取得基本資訊
   */
  index(id) {
    return axios.get(settings.api.fullPath + `/ems/profile/class/${id}`)
  }
  /**
   * 更新基本資訊
   */
  update(id, params) {
    return axios.put(settings.api.fullPath + `/ems/profile/class/${id}`, params)
  }
}

var branchClass = new BranchClass()
export default branchClass
